import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col, Container, Row } from 'reactstrap';
import Parse from 'parse';
import { connect } from 'react-redux';
import cn from 'classnames';
import Fade from 'react-reveal/Fade';
import withStyles from 'isomorphic-style-loader/withStyles';

import { ErrorAlert } from 'helpers/alert';
import handleParseError from 'helpers/handleParseError';
import Link from 'components/Link';
import { setSpecialties, setSelectedSpecialty } from 'actions/specialty';

import s from './Home.scss';

class Home extends React.Component {
  static propTypes = {
    isAuth: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    specialties: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    setSpecialties: PropTypes.func.isRequired,
    setSelectedSpecialty: PropTypes.func.isRequired,
  };

  static defaultProps = {
    specialties: [],
  };

  constructor() {
    super();
    this.state = {
      fadeIn: false,
    };
  }

  componentDidMount() {
    if (_.isEmpty(this.props.specialties)) {
      const query = new Parse.Query('Specialty');
      query.addAscending('name');
      query.include('image');
      query
        .find()
        .then(specialties => {
          this.props.setSpecialties({ specialties });
        })
        .then(() => {
          this.setState({ fadeIn: true });
        })
        .catch(
          handleParseError(error => {
            ErrorAlert(error.message);
          }),
        );
    } else {
      this.setState({ fadeIn: true });
    }
  }

  render() {
    const elts = [];
    if (this.props.isAuth && this.props.isAdmin) {
      elts.push([
        <Col xs={12} sm={6} lg={4} key={_.uniqueId('col_')} className="my-1">
          <Link to="/program" className={s.buttonLink}>
            <button type="button" className="btn btn-secondary-color-1 btn-lg">
              Program Management
            </button>
          </Link>
        </Col>,
      ]);
    }
    elts.push([
      <Col xs={12} sm={6} lg={4} className="my-1">
        <Link to="/takeSurvey" className={s.buttonLink}>
          <button type="button" className="btn btn-secondary-color-1 btn-lg">
            Take Survey
          </button>
        </Link>
      </Col>,
    ]);
    const loginArea = (
      <Row key={_.uniqueId('row_')} className="justify-content-center">
        {elts}
      </Row>
    );

    return (
      <div className={s.root}>
        <header>
          <div className="jumbotron">
            <Container fluid>
              <div id="login-area" className="text-center">
                {loginArea}
              </div>
            </Container>
          </div>
        </header>
        <div className={cn([s.specialtyContainer, 'py-5'])}>
          {this.props.specialties.length > 0 ? (
            <Fade bottom when={this.state.fadeIn}>
              <Row>
                <Col lg={12} className="page-header text-center">
                  <h2 style={{ color: 'black' }}>Participating Specialties</h2>
                </Col>
              </Row>
              <Row className={cn(['mb-3', 'justify-content-center'])}>
                {_.map(this.props.specialties, specialty => (
                  /* eslint-disable no-underscore-dangle */
                  <Col
                    md={4}
                    sm={12}
                    className="text-center"
                    key={_.uniqueId('homediv_')}
                    style={{ height: '250px' }}
                  >
                    <Link
                      onClick={() =>
                        this.props.setSelectedSpecialty({ specialty })
                      }
                      to="/"
                    >
                      <img
                        className={cn([s.image, 'img-circle'])}
                        alt={specialty.get('name')}
                        src={
                          (specialty.get('image') &&
                            specialty.get('image')._url) ||
                          'http://via.placeholder.com/200x200'
                        }
                        data-holder-rendered
                      />
                      <h3>{specialty.get('name')}</h3>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Fade>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  coordinator: state.user.coordinator,
  specialties: state.specialty.specialties,
  ...state.auth,
});

const mapDispatchToProps = {
  setSpecialties,
  setSelectedSpecialty,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(Home));
