// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".qf4cd{padding-top:100px}._3d_qn{color:inherit}._3d_qn>button{width:100%}._1Bcgr{background-color:#fff}._1Bcgr a{color:#ff5349}._1Bcgr a:hover{color:#94302a}.S8VE0{width:200px;height:200px}", ""]);
// Exports
exports.locals = {
	"root": "qf4cd",
	"buttonLink": "_3d_qn",
	"specialtyContainer": "_1Bcgr",
	"image": "S8VE0"
};
module.exports = exports;
